import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//los containers posta
import { ContainersComponent } from './pages/containers/containers.component';
import { ContainerComponent } from './pages/container/container.component';
import { ReportesComponent } from './pages/reportes/reportes.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { InformeComponent } from './pages/informe/informe.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  
  { path: '', redirectTo: 'login', pathMatch: 'prefix'},
  { path: 'login', component: LoginComponent},
  { path: 'registro', component: RegistroComponent},
  { path: 'containers', component: ContainersComponent, canActivate: [AuthGuard]},
  { path: 'container/:id', component: ContainerComponent, canActivate: [AuthGuard]},
  { path: 'reportes', component: ReportesComponent, canActivate: [AuthGuard]},
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard]},
  { path: 'informe', component: InformeComponent, canActivate: [AuthGuard]}
  //{ path: '**', redirectTo: 'login' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
