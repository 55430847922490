import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContainerModel } from '../models/container.model';
import { map, filter } from 'rxjs/operators';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';
import { MenuController } from '@ionic/angular';
import { AumentoPrecio } from '../models/aumentoPrecio.model';

@Injectable({
  providedIn: 'root'
})

export class ContService {

  listaClientes: any[] = [];
  containers: ContainerModel[] = [];

  username: string = localStorage.getItem('username');

  private url= 'https://crud-angular-a54b5-default-rtdb.firebaseio.com';

  constructor( private http: HttpClient, private db: AngularFireDatabase, public menuCtrl: MenuController ){
  }

  toggleMenu(){
    this.menuCtrl.toggle();
  }

  getAumento(){
    return this.http.get(`${ this.url }/aumento.json`).pipe( map( this.crearArregloGenerico  ) );
  }

  crearAumento(aum: AumentoPrecio){
    return this.http.post(`${ this.url }/aumento.json`, aum)
    .pipe(
      map( (resp: any) => {
        aum.id = resp.name;
        return aum;
      })
    );
  }

  actualizarAumento(aum: AumentoPrecio){
    const aumentoTemp = {
      ...aum
    };

    delete aumentoTemp.id;

    return this.http.put(`${ this.url }/aumento/${ aum.id }.json`, aumentoTemp);
  }

  crearContainer( container: ContainerModel){

    return this.http.post(`${ this.url }/containers.json`, container)
      .pipe(
        map( (resp: any) => {
          container.id = resp.name;
          return container;
        })
      );

  }

  pushToHistorial( container: ContainerModel){

    container.historialDe = container.id;
    delete container.id;
    return this.http.post(`${ this.url }/historial.json`, container)
      .pipe(
        map( (resp: any) => {
          container.id = resp.name;
          return container;
        })
      );

  }

  actualizarContainer( container: ContainerModel){

    const containerTemp = {
      ...container
    };

    delete containerTemp.id;

    return this.http.put(`${ this.url }/containers/${ container.id }.json`, containerTemp);
  }

  borrarContainer( id: string | undefined) {
    return this.http.delete(`${ this.url }/containers/${ id }.json`);
  }

  getContainer( id: string | null ) {
    return this.http.get(`${ this.url }/containers/${ id }.json`);
  }

  getContainers(){
    return this.http.get(`${ this.url }/containers.json`).pipe( map( this.crearArreglo  ) );
  }

  getHistorialContainer(id: string | null){
    /*
    const historial = this.db.list('/containers/historial', ref => ref.orderByChild('historialDe').equalTo(id)).valueChanges();
    historial.subscribe(console.log);
    */

   return this.db.list('historial', ref => ref.orderByChild('historialDe').equalTo(id)).valueChanges([],{ idField: 'id'}).pipe( map( this.arrayHist  ) );

  }

  borrarHistorial( id: string | undefined) {
    return this.http.delete(`${ this.url }/historial/${ id }.json`);
  }

  getClientes(){
    return this.http.get(`${ this.url }/clientes.json`).pipe( map( this.crearArregloClientes  ) );
  }

  cambioCliente(cambio){
    return this.http.post(`${ this.url }/cambioCliente.json`, cambio);
  }



  diferenciaMeses(fecha) {
    const dia = parseInt(fecha.substring(8,10));
    const mes = parseInt(fecha.substring(5,7));
    const anio = parseInt(fecha.substring(0,4));
    const fechaCambio = new Date(anio,mes,dia);
    const fechaActual = new Date();

    let months;
    months = (fechaActual.getFullYear() - fechaCambio.getFullYear()) * 12;
    months -= fechaCambio.getMonth();
    months += fechaActual.getMonth()+1;
    return months <= 0 ? 0 : months;
   }



  private crearArreglo(containersObj : any){
    const containers: ContainerModel[] = [];
    if (containersObj === null){ return []; }
    for(let registro in containersObj){
      containersObj[registro].id = registro;
      containers.push(containersObj[registro]);
    }
    return containers;
  }

  private crearArregloClientes(clientesObj : any){
    const clientes: any[] = [];
    if (clientesObj === null){ return []; }
    for(let registro in clientesObj){
      clientesObj[registro].id = registro;
      clientes.push(clientesObj[registro]);
    }
    return clientes;
  }

  private crearArregloGenerico(obj: any){
    const arreglo: any[] = [];
    if (obj === null){ return []; }
    for(let registro in obj){
      obj[registro].id = registro;
      arreglo.push(obj[registro]);
    }
    return arreglo;
  }

  private arrayHist(obj: any){
    const arreglo: any[] = [];
    if (obj === null){ return []; }
    for(let registro in obj){
      //obj[registro].id = registro;
      arreglo.push(obj[registro]);
    }
    return arreglo;
  }



}
