/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit } from '@angular/core';
import { ReportesService } from '../../services/reportes.service';

import { ContainerModel } from '../../models/container.model';
import { PagoModel } from '../../models/pago.model';
import { DeudaModel } from '../../models/deuda.model';
import { ClienteModel } from 'src/app/models/cliente.model';

import Swal from 'sweetalert2';
import { MenuController } from '@ionic/angular';
import { ClienteService } from 'src/app/services/cliente.service';


@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html'
})

export class ReportesComponent implements OnInit {


  anioSelected: number;

  pantalla = 0; //0-reportes, 1-newPago, 2-newDeuda
  editar = false;

  clientes: ClienteModel[] = [];
  containers: ContainerModel[] = [];
  deudas: DeudaModel[] = [];
  pagos: PagoModel[] = [];

  cliente: ClienteModel;
  saldo = 0;
  newPago: PagoModel = new PagoModel();
  newDeuda: DeudaModel = new DeudaModel();

  montoViejoPago: number;
  montoViejoDeuda: number;


  mostrarDeudas = true;
  mostrarPagos = true;
  mostrarSaldo = false;

  cargando = true;

  mostrarAccion = {tipo: 'deuda', index: -1};
  mostrarAccionDeuda = -1;
  mostrarAccionPago = -1;

  sortDesc = true;


  constructor( private reportesService: ReportesService, private clienteService: ClienteService, public menuCtrl: MenuController ) { }

  ngOnInit() {
    this.reportesService.getClientes().subscribe( respuesta => {
      this.clientes = respuesta;
      this.clientes = this.clientes.filter(item => item.nombre !== 'Campo').filter(i => i.nombre !== 'depositosExt');
      this.clientes.sort((a, b)=> {
        if (a.nombre > b.nombre) {return 1;}
        if (a.nombre < b.nombre) {return -1;}
        return 0;
      });
    });
  }

  toggleMenu(){
    this.menuCtrl.toggle();
  }

  //cuando el select de cliente cambia de value
  async clienteSelected(evento){
    const clienteSelected = this.clientes.filter(cliente => cliente.nombre === evento.detail.value);
    this.cliente = clienteSelected[0];
    this.cargarDatos();
  }

  cargarDatos(){
    this.mostrarSaldo = false;
    this.cargando = true;
    this.reportesService.getContainersCliente(this.cliente.id).subscribe( respuesta => {
      this.containers = respuesta;
      this.reportesService.getDeudasCliente(this.cliente.id).subscribe( resDeudas => {
        this.deudas = resDeudas;
        this.reportesService.getPagosCliente(this.cliente.id).subscribe( resPagos => {
          this.pagos = resPagos;
          this.cargando = false;
          this.getSaldoCliente();
          this.sortByFecha();
        });
      });
    });
  }

  getContainersCliente(cliente: string){
    this.reportesService.getContainersCliente(this.cliente.nombre).subscribe( respuesta => {
      this.containers = respuesta;
    });
  };

  async getDeudas(){
    this.reportesService.getDeudasCliente(this.cliente.id).subscribe( respuesta => {
      this.deudas = respuesta;
    });
  }

  async getPagos(){
    this.reportesService.getPagosCliente(this.cliente.id).subscribe( respuesta => {
      this.pagos = respuesta;
    });
  }

  async checkDeudaMes(){
    const fechaHoy = new Date();
    const anioActual = fechaHoy.getFullYear();
    const mesActual = fechaHoy.getMonth() + 1;
    const diaActual = fechaHoy.getDate();
    let ultimaDeuda = new DeudaModel();

    console.log(`fecha actual: ${diaActual}/${mesActual}/${anioActual}`);

    if (this.deudas[this.deudas.length - 1]) {ultimaDeuda = this.deudas[this.deudas.length - 1];};

    if (ultimaDeuda.anio !== anioActual || ultimaDeuda.mes !== mesActual) {

      console.log('No existía deuda este mes, se agrega.');

      this.containers.forEach(container => {
        const deuda: DeudaModel = {
          cliente: this.cliente.nombre,
          contenedor: container.numero,
          anio: anioActual,
          mes: mesActual,
          dia: diaActual,
          precio: container.precio
        };

        this.guardarDeuda(deuda);

      });

    }
  }

  getSaldoCliente(guardar=true){
    //if(this.cliente.saldo && this.cliente.saldo !== 0) { this.cliente.saldo = this.cliente.deudaInicial; } else { this.cliente.saldo = 0; }
    this.cliente.saldo = 0;
    if(!this.cliente.deudaInicial) this.cliente.deudaInicial = 0;
    this.deudas.forEach(deuda => {
      this.cliente.saldo= this.cliente.saldo + deuda.precio;
    });
    this.pagos.forEach(pago => {
      this.cliente.saldo= this.cliente.saldo - pago.cantidad;
    });
    this.mostrarSaldo = true;
    if(guardar==true){
      this.clienteService.actualizarCliente(this.cliente).subscribe(res=>{
        console.log('saldo actualizado: ' + this.cliente.saldo);
      });
    }
  }

  containerSelected(evento){
    this.newPago.contenedor = evento.detail.value;
    this.newDeuda.contenedor = evento.detail.value;
  }

  validarPago(){
    let errores = '';
    if(this.newPago.anio === (0 || null) || this.newPago.anio.toString().length !== 4) {errores+='El año es inválido.';}
    if(this.newPago.mes === null || this.newPago.mes < 1 || this.newPago.mes > 12) {errores+='El mes es inválido.';}
    if(this.newPago.dia === null || this.newPago.dia < 1 || this.newPago.dia > 31) {errores+='El día es inválido.';}
    if(this.newPago.cantidad === 0 || this.newPago.cantidad === null) {errores+='El monto debe ser mayor que 0.';}
    //if(this.newPago.contenedor === 0) {errores+='No se ha asignado un contenedor.';}
    
    if(errores.length !== 0) {
      Swal.fire({
        title: 'Error',
        text: errores,
        icon: 'error',
        allowOutsideClick: false
      });
    }else{
      this.guardarPago();
    }
  }

  guardarPago(){

    this.newPago.cliente = this.cliente.id;

    Swal.fire({
      title: 'Espere',
      text: 'Guardando pago',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    if(this.editar){
      this.reportesService.actualizarPago( this.newPago ).subscribe( resp => {
        // if(this.newPago.cantidad !== this.montoViejoPago){
        //   this.cliente.saldo+= this.montoViejoPago;
        //   this.cliente.saldo-= this.newPago.cantidad;
        // }
        Swal.fire({
          title: 'Pago guardado',
          text: 'Se guardó el pago.',
          icon: 'success'
        });
        this.cambiarPantalla(0);
        this.cargarDatos();
      });
    }else{
      this.reportesService.newPago( this.newPago ).subscribe( resp => {
        // this.cliente.saldo-= this.newPago.cantidad;
        Swal.fire({
          title: 'Pago guardado',
          text: 'Se guardó el pago.',
          icon: 'success'
        });
        this.cambiarPantalla(0);
        this.cargarDatos();
      });
    }
  }

  eliminarPago(pago: PagoModel, i: number) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar este pago?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if (resp.value) {
        this.reportesService.deletePago( pago.id ).subscribe(res=>{
          this.pagos.splice(i, 1);
          this.getSaldoCliente();
          Swal.fire({
            title: 'Pago eliminado',
            text: 'Se eliminó el pago.',
            icon: 'success'
          });
        });
        
      }
    });

  }

  validarDeuda(deuda: DeudaModel){
    let errores = '';
    if(this.newDeuda.anio === (0 || null) || this.newDeuda.anio.toString().length !== 4) {errores+='El año es inválido.';}
    if(this.newDeuda.mes === null || this.newDeuda.mes < 1 || this.newDeuda.mes > 12) {errores+='El mes es inválido.';}
    if(this.newDeuda.dia === null || this.newDeuda.dia < 1 || this.newDeuda.dia > 31) {errores+='El día es inválido.';}
    if(this.newDeuda.precio === 0 || this.newDeuda.precio === null) {errores+='El monto debe ser mayor que 0.';}
    //if(this.newDeuda.contenedor === 0) {errores+='No se ha asignado un contenedor.';}
    
    if(errores.length !== 0) {
      Swal.fire({
        title: 'Error',
        text: errores,
        icon: 'error',
        allowOutsideClick: false
      });
    }else{
      this.guardarDeuda(deuda);
    }
  }

  guardarDeuda(deuda: DeudaModel){

    this.newDeuda.cliente = this.cliente.id;

    Swal.fire({
      title: 'Espere',
      text: 'Guardando deuda',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    if(this.editar){
      this.reportesService.actualizarDeuda(this.newDeuda).subscribe( respuesta => { 
        Swal.fire({
          title: 'Deuda actualizada',
          text: 'Se actualizó la deuda.',
          icon: 'success'
        });
        this.cambiarPantalla(0);
        this.cargarDatos();
        // if(this.newDeuda.precio !== this.montoViejoDeuda){
        //   this.cliente.saldo-= this.montoViejoDeuda;
        //   this.cliente.saldo+= this.newDeuda.precio;
        // }
      });

    }else{
      this.reportesService.newDeudaCliente(this.newDeuda).subscribe( respuesta => {
        // this.cliente.saldo+= this.newDeuda.precio;
        Swal.fire({
          title: 'Deuda agregada',
          text: 'Se agregó la deuda.',
          icon: 'success'
        });
        this.cambiarPantalla(0);
        this.cargarDatos();
      });
    }
  }

  eliminarDeuda(deuda: DeudaModel, i: number) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar esta deuda?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if (resp.value) {
        this.reportesService.deleteDeuda( deuda.id ).subscribe();
        this.deudas.splice(i, 1);
        this.getSaldoCliente();
        Swal.fire({
          title: 'Deuda eliminada',
          text: 'Se eliminó la deuda.',
          icon: 'success'
        });
      }

    });

  }

  mostrarAcciones(index: number, tipo: number){
    if(tipo === 0){
      this.mostrarAccionPago = -1;
      if(this.mostrarAccionDeuda === index) {
        this.mostrarAccionDeuda = -1;
      }else{
        this.mostrarAccionDeuda = index;
      }
    }else{
      this.mostrarAccionDeuda = -1;
      if(this.mostrarAccionPago === index) {
        this.mostrarAccionPago = -1;
      }else{
        this.mostrarAccionPago = index;
      }
    }

  }

  cambiarPantalla(pantalla: number, data?: any){
    this.pantalla = pantalla;
    if(data){
      this.editar = true;
      if(pantalla === 1) {
        this.montoViejoPago = data.cantidad;
        this.newPago = data;
      }
      if(pantalla === 2) {
        this.montoViejoDeuda = data.precio;
        this.newDeuda = data;
      }
    }else{
      this.editar = false;
      this.newPago = new PagoModel();
      this.newDeuda = new DeudaModel();
    }
  }

  bgColor(tipo, index: number){
    let a;
    if(tipo === 0) {a = this.mostrarAccionDeuda;}
    if(tipo === 1) {a = this.mostrarAccionPago;}
    if(a === index) {
      return '#b8cfd6';
    }else{
      return 'transparent';
    }
  }

  sortByFecha(){
    this.sortDesc = !this.sortDesc;
    const orden = this.sortDesc ? 1 : (-1);
    this.deudas.sort((a, b)=> {
      const fechaA = new Date(`${a.mes}/${a.dia}/${a.anio}`);
      const fechaB = new Date(`${b.mes}/${b.dia}/${b.anio}`);;
      if (fechaA > fechaB) {
        return orden;
      }
      if (fechaA < fechaB) {
        return orden * (-1);
      }
      // a must be equal to b
      return 0;
    });

    this.pagos.sort((a, b)=> {
      const fechaA = new Date(`${a.mes}/${a.dia}/${a.anio}`);
      const fechaB = new Date(`${b.mes}/${b.dia}/${b.anio}`);;
      if (fechaA > fechaB) {
        return orden;
      }
      if (fechaA < fechaB) {
        return orden * (-1);
      }
      // a must be equal to b
      return 0;
    });
  }

}
