// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCHvSTBzeFGOu5KauXQFQODZgyC43gtnRE",
    authDomain: "crud-angular-a54b5.firebaseapp.com",
    databaseURL: "https://crud-angular-a54b5-default-rtdb.firebaseio.com",
    projectId: "crud-angular-a54b5",
    storageBucket: "crud-angular-a54b5.appspot.com",
    messagingSenderId: "872876142330",
    appId: "1:872876142330:web:a85ceaa6910b524421a343"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
