import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienteModel } from '../models/cliente.model';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  private url= 'https://crud-angular-a54b5-default-rtdb.firebaseio.com';

  constructor(private http: HttpClient) { }

  modificarSaldoCliente(cliente: ClienteModel, monto: number){
    cliente.saldo+= monto;
    this.actualizarCliente(cliente).subscribe(res => {

    });
  }

  crearCliente( cliente: ClienteModel){
    return this.http.post(`${ this.url }/clientes.json`, cliente)
    .pipe(
      map( (resp: any) => {
        cliente.id = resp.name;
        return cliente;
      })
    );
  }

  actualizarCliente( cliente: ClienteModel){

    const clienteTemp = {
      ...cliente
    };

    delete clienteTemp.id;

    return this.http.put(`${ this.url }/clientes/${ cliente.id }.json`, clienteTemp);
  }

  borrarCliente( id: string) {
    return this.http.delete(`${ this.url }/clientes/${ id }.json`);
  }

  getCliente( id: string | null ) {
    return this.http.get(`${ this.url }/clientes/${ id }.json`);
  }

  getClientes(){
    return this.http.get(`${ this.url }/clientes.json`).pipe( map( this.crearArreglo  ) );
  }

  private crearArreglo(clientesObj : any){
    const clientes : ClienteModel[] = [];
    if (clientesObj === null){ return []; }
    for(let registro in clientesObj){
      clientesObj[registro].id = registro;
      clientes.push(clientesObj[registro]);
    }
    return clientes;
  }

}
