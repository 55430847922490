/* eslint-disable radix */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ContService } from '../../services/contService.service';
import { ContainerModel } from '../../models/container.model';
import Swal from 'sweetalert2';
import { AumentoPrecio } from 'src/app/models/aumentoPrecio.model';
import { Observable } from 'rxjs';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.css']
})
export class ContainersComponent {

  containers: ContainerModel[] = [];
  cargando = false;
  diasPrecio = [];
  mesesPrecio = [];

  //del form precio
  formPrecio = false;
  aumento: AumentoPrecio;
  mesesAumento = 0;
  cantAumento = 0;
  necesitanAumento: ContainerModel[] = [];
  formEnabled = false;

  listaClientes: any[] = [];

  diasParaVencimiento: number[] = [];

  sortBy = 0;
  sortDesc = 1;

  filtroCliente= '';

  constructor( private contService: ContService, public menuCtrl: MenuController) { }

  ionViewWillEnter(){   //es el oninit de ionic que anda mejor que el oninit
    this.cargando = true;
    this.getAumento();
    this.getDatos();
  }

  toggleMenu(){
    this.menuCtrl.toggle();
  }

  getDatos(){
    this.contService.getClientes().subscribe( res => {
      this.listaClientes = res;
      this.contService.getContainers().subscribe( respuesta => {
        this.containers = respuesta;
        console.log(this.containers[0]);
        this.sortList(0,1);
        this.cargando = false;
        this.necesitanAumento = [];
        this.containers.forEach(cont => {
          //const vencido = cont.fechaVencimiento && this.diferenciaMeses(cont.fechaVencimiento) > 0 ? true : false;
          //const necesitaAumento = this.diferenciaMeses(cont.fechaCambioPrecioAlquiler) >= this.aumento.meses ? true : false;
          this.diasParaVencimiento.push(this.diferenciaDias(cont.fechaVencimiento, new Date()));
          const vencido = this.vencio(cont);

          if(this.necesitaAumento(cont)) {this.necesitanAumento.push(cont);}
        });
      });
    });
  }

  vencio(container){
    if(this.getNombreCliente(container.cliente) === ('Campo' || 'depositosExt')) return false;
    const difDias = this.diferenciaDias(new Date(), container.fechaVencimiento);
    if(container.fechaVencimiento && difDias >= 0) return true;
    return false;
    //if(container.fechaVencimiento && this.diferenciaMeses(container.fechaVencimiento) > 0) return true;
  }

  necesitaAumento(container){
    if(this.diferenciaDias(new Date(), container.fechaCambioPrecioAlquiler) >= this.aumento.meses * 30) {
      return true;
    }else{
      return false;
    };
  }

  diasParaAumento(container){
    const diasQuePasaron = this.diferenciaDias(new Date(), container.fechaCambioPrecioAlquiler);
    return  this.aumento.meses * 30 - diasQuePasaron;
  }

  getNombreCliente(id){
    return this.listaClientes.find(x => x.id === id).nombre;
  }

  // calcularDiferenciaDias(fecha, fecha2?){
  //   const fechaCambio = new Date(fecha);
  //   let fechaActual;
  //   if(fecha2) {fechaActual = new Date(fecha2);}else{new Date();}
  //   const timeDifference = fechaActual.getTime() - fechaCambio.getTime();
  //   const daysDifference = timeDifference / (1000 * 3600 * 24);
  //   return daysDifference;
  // }

  diferenciaDias(f1,f2){
    const date1 = new Date(f1);
    const date2 = new Date(f2);

    const days = (date1, date2) =>{
        const difference = date1.getTime() - date2.getTime();
        let totalDays = Math.floor(difference / (1000 * 3600 * 24));
        return totalDays;
    };
    return (days(date1, date2));
  }

  diferenciaMeses(fecha) {

    let dia;
    let mes;
    let anio;

    if(typeof fecha !== 'string') {
      dia = fecha.getDate();
      mes = fecha.getMonth() + 1;
      anio = fecha.getFullYear();
    }else{
      dia = parseInt(fecha.substring(8,10));
      mes = parseInt(fecha.substring(5,7));
      anio = parseInt(fecha.substring(0,4));
    }

    const fechaCambio = new Date(anio,mes,dia);
    const fechaActual = new Date();

    let months;
    months = (fechaActual.getFullYear() - fechaCambio.getFullYear()) * 12;
    months -= fechaCambio.getMonth();
    months += fechaActual.getMonth()+1;
    return months <= 0 ? 0 : months;
   }

  borrarContainer( container: ContainerModel, i: number) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar el container #${ container.numero }?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if (resp.value) {
        this.contService.borrarContainer( container.id ).subscribe();
        this.containers.splice(i, 1);
      }

    });

  }

  get preciosVencidosString(){
    let string = '';
    this.necesitanAumento.forEach(cont =>{
      const num = cont.numero.toString();
      string = string + num + ', ';
    })
    string = string.slice(0,-2);
    return string;
  }

  aumentarPrecios(){
    Swal.fire({
      title: '¿Aumentar precios?',
      text: `¿Aumentar el precio de alquiler en un ${this.aumento.cantidad}% de los contenedores desactualizados?
      Contenedores: ${this.preciosVencidosString}`,
      icon: 'question',
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(res => {
      if(res.isConfirmed) {

        let updateados = 0;

        this.necesitanAumento.forEach(contAumentado => {
          const aumento = (contAumentado.precio * this.aumento.cantidad) / 100;

          contAumentado.precio = contAumentado.precio + aumento;
          contAumentado.fechaCambioPrecioAlquiler = new Date();

          this.contService.actualizarContainer(contAumentado).subscribe(() => {
            updateados = updateados +1;
            if(updateados === this.necesitanAumento.length) {
              Swal.fire({
                title: 'Precios actualizados',
                text: `Se han actualizado los precios correctamente`,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Aceptar',
                showCancelButton: false
              });
              this.getDatos();
            }
          });
        });

      }
    });
  }

  /* FORM AUMENTO PRECIO */

  mostrarFormAumento(){
    this.formPrecio = true;
    this.getAumento();
  }


  getAumento(){
    this.contService.getAumento().subscribe( (aumento: AumentoPrecio[]) => {
      if(aumento.length > 0){
        this.aumento = aumento[0];
        this.aumento.id = aumento[0].id;
      }
    });
  }

  guardarAumento(){
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    let peticion: Observable<any>;

    if (this.aumento.id) {
      peticion = this.contService.actualizarAumento( this.aumento );
    }else{
      peticion = this.contService.crearAumento( this.aumento );
    }

    peticion.subscribe( resp => {
        Swal.fire({
          title: 'Aumento estimado actualizado',
          text: 'Se ha actualizado la información',
          icon: 'success'
        });
        this.cancelarEditarAumento();
    });

  }

  cancelarEditarAumento(){
    this.formEnabled = false;
  }

  bgColor(i){
    if((i % 2) == 0) {return '#e3eeff'} else {return 'transparent'};
  }

  sortList(by:number, desc?){
    this.sortBy = by;
    this.sortDesc = this.sortDesc * (-1);
    if(desc) this.sortDesc = desc;
    if(by === 1){
      this.containers.sort((a, b)=> {
        const nombreA = this.getNombreCliente(a.cliente);
        const nombreB = this.getNombreCliente(b.cliente);
        if (nombreA > nombreB) {return this.sortDesc;}
        if (nombreA < nombreB) {return this.sortDesc * (-1);}
        return 0;
      });
    }else{
      this.containers.sort((a, b)=> {
        if (a.numero > b.numero) {return this.sortDesc;}
        if (a.numero < b.numero) {return this.sortDesc * (-1);}
        return 0;
      });
    }

  }

  filtrarCliente(e){
    this.filtroCliente = e.detail.value;
  }

}
