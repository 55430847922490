import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import jwt_decode from 'jwt-decode';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  usuario: UsuarioModel = new UsuarioModel();
  
  errorForm: string;

  constructor( private auth: AuthService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    if(localStorage.getItem('email')){
      this.usuario.email = localStorage.getItem('email');
    }
  }

  login(){
    
    delete this.errorForm;
    if(!this.usuario.password) { this.errorForm = 'La contraseña es obligatoria.'; }
    if(this.usuario.password && this.usuario.password.length < 6) { this.errorForm = 'La contraseña debe tener al menos 6 caracteres.'; }
    if(!this.usuario.email || this.usuario.email.length < 6) { this.errorForm = 'El correo electrónico es obligatorio.'; }

    if(this.errorForm) {
      Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Error al iniciar sesión',
        text: this.errorForm
      });

      return false;
    }

    this.auth.login( this.usuario).subscribe(res=> {

      Swal.close();

      const token = res['idToken'];
      const decoded = jwt_decode(token);
      const uid = decoded['user_id'];

      this.auth.getUsuario(uid).subscribe(resp => {
        localStorage.setItem('username', resp[0].username);
        this.userService.username = resp[0].username;
        this.router.navigateByUrl('/containers');
      });

    }, (err) => {

      Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Error al iniciar sesión',
        text: 'Ha habido un error al iniciar sesión'
      });
      console.log(err.error.error.message);

    });
  }

}