/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../../services/cliente.service';
import { ClienteModel } from '../../models/cliente.model';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { MenuController } from '@ionic/angular';
import { ReportesService } from 'src/app/services/reportes.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html'
})
export class ClientesComponent implements OnInit {

  cargando = false;
  estado = 0; //0: lista, 1: nuevo, 2: editar
  clientes: ClienteModel[] = [];
  cliente= new ClienteModel();

  nombreCliente: string;
  SaldoInicialPrevio: number;

  mostrarOpciones = -1;

  constructor( private clienteService: ClienteService, private reportesService: ReportesService, public menuCtrl: MenuController) { }

  ngOnInit() {
    this.cargando = true;
    this.getClientes();
  }

  toggleMenu(){
    this.menuCtrl.toggle();
  }

  getClientes(){
    this.clienteService.getClientes().subscribe( respuesta => {
      this.clientes = respuesta;
      this.clientes.sort((a, b)=> {
        if (a.nombre > b.nombre) {return 1;}
        if (a.nombre < b.nombre) {return -1;}
        return 0;
      });
      this.cargando = false;
      console.log(this.clientes);
    });
  }

  cambiarEstado(estado, cliente?: ClienteModel){
    this.estado = estado;
    if(estado === 2 && cliente) {
      this.nombreCliente = cliente.nombre;
      this.SaldoInicialPrevio = cliente.deudaInicial;
    }
    cliente ? this.cliente = cliente : this.cliente = new ClienteModel();
  }

  verificarNombre(){
    const existeNombre = this.clientes.find(cli => cli.nombre === this.cliente.nombre);
    console.log(existeNombre);

    if(existeNombre && existeNombre.nombre !== this.nombreCliente){
      Swal.fire({
        title: 'Error',
        text: 'Ya existe un cliente con ese nombre',
        icon: 'error'
      });
    }else{
      if(this.estado === 2){this.updateCliente();} else {this.agregarCliente();}
    }
  }

  agregarCliente(){

    Swal.fire({
      title: 'Espere',
      text: 'Guardando cliente',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    this.cliente.saldo = this.cliente.deudaInicial;

    this.clienteService.crearCliente( this.cliente ).subscribe( resp => {

      Swal.fire({
        title: this.cliente.nombre,
        text: 'Se ha actualizado',
        icon: 'success'
      });

      this.cambiarEstado(0);
      this.getClientes();

    });


  }

  updateCliente(){
    Swal.fire({
      title: 'Espere',
      text: 'Actualizando cliente',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    if(!this.cliente.saldo) this.cliente.saldo = 0;

    if(this.cliente.deudaInicial !== this.SaldoInicialPrevio){
      this.cliente.saldo-= this.SaldoInicialPrevio;
      this.cliente.saldo+= this.cliente.deudaInicial;
    }

    this.clienteService.actualizarCliente( this.cliente ).subscribe( resp => {

      Swal.fire({
        title: this.cliente.nombre,
        text: 'Se ha actualizado',
        icon: 'success'
      });

      this.cambiarEstado(0);
      this.getClientes();
    });
  }

  eliminarCliente( cliente: ClienteModel, i: number) {

    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar el cliente '${ cliente.nombre }'?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then( resp => {

      if (resp.value) {
        this.clienteService.borrarCliente( cliente.id ).subscribe();
        this.clientes.splice(i, 1);
      }

    });

  }

/*-----------------*/

  mostrarAcciones(index){
    this.mostrarOpciones === index ? this.mostrarOpciones = -1 : this.mostrarOpciones = index;
  }

  bgColor(index){
    if(this.mostrarOpciones === index) {
      return '#b8cfd6';
    }else{
      return 'transparent';
    }
  }

}
