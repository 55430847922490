/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContainerModel } from '../models/container.model';
import { map, filter } from 'rxjs/operators';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';
import { DeudaModel } from '../models/deuda.model';
import { PagoModel } from '../models/pago.model';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  private url= 'https://crud-angular-a54b5-default-rtdb.firebaseio.com';

  constructor( private http: HttpClient, private db: AngularFireDatabase) { }

  getClientes(){
    return this.http.get(`${ this.url }/clientes.json`).pipe(map( this.crearArreglo));
  }

  getHistorialByCliente(idCliente: string | null){
   return this.db.list('historial', ref => ref.orderByChild('cliente').equalTo(idCliente)).valueChanges().pipe( map( this.crearArregloContainers  ) );
  }

  getContainersCliente(idCliente: string | null){
    return this.db.list('containers', ref => ref.orderByChild('cliente').equalTo(idCliente)).valueChanges().pipe( map( this.crearArregloContainers  ) );
  }

  // newDeudaCliente(deuda){
  //   return this.http.post(`${ this.url }/deudas.json`, deuda);
  // }


    /* DEUDAS */

  getAllDeudas(){
    return this.http.get(`${ this.url }/deudas.json`).pipe(map( this.crearArreglo));
  }

  getDeudasCliente(idCliente: string | null){
    return this.db.list('deudas', ref => ref.orderByChild('cliente').equalTo(idCliente)).valueChanges([],{ idField: 'idCustom' }).pipe(map(this.newArrayDeudaPago));
  }

  newDeudaCliente( deuda: DeudaModel){

    return this.http.post(`${ this.url }/deudas.json`, deuda)
      .pipe(
        map( (resp: any) => {
          deuda.id = resp.name;
          return deuda;
        })
      );

  }

  actualizarDeuda(deuda: DeudaModel){
    const deudaTemp = {...deuda};
    delete deudaTemp.id;
    return this.http.put(`${ this.url }/deudas/${ deuda.id }.json`, deudaTemp);
  }

  deleteDeuda( id: string) {
    return this.http.delete(`${ this.url }/deudas/${ id }.json`);
  }

  deleteDeudasByCliente( clienteId: number) {
    return this.http.delete(`${ this.url }/deudas/${ clienteId }.json`);
  }



  /* PAGOS */

  getPagosCliente(idCliente: string | null){
    return this.db.list('pagos', ref => ref.orderByChild('cliente').equalTo(idCliente)).valueChanges([],{ idField: 'idCustom' }).pipe(map(this.newArrayDeudaPago));
  }

  getAllPagos(){
    return this.http.get(`${ this.url }/pagos.json`).pipe(map( this.crearArreglo));
  }

  newPago(pago){
    return this.http.post(`${ this.url }/pagos.json`, pago);
  }

  actualizarPago(pago: PagoModel){
    const pagoTemp = {...pago};
    delete pagoTemp.id;
    return this.http.put(`${ this.url }/pagos/${ pago.id }.json`, pagoTemp);
  }

  deletePago( id: string) {
    return this.http.delete(`${ this.url }/pagos/${ id }.json`);
  }



  /* ARREGLOS */

  private crearArregloContainers(containersObj: any){
    const containers: ContainerModel[] = [];
    if (containersObj === null){ return []; }
    // eslint-disable-next-line guard-for-in
    for(const registro in containersObj){
      containersObj[registro].id = registro;
      containers.push(containersObj[registro]);
    }
    return containers;
  }

  private crearArreglo(obj: any){
    const arreglo: any[] = [];
    if (obj === null){ return []; }
    // eslint-disable-next-line guard-for-in
    for(const registro in obj){
      obj[registro].id = registro;
      arreglo.push(obj[registro]);
    }
    return arreglo;
  }

  private newArrayDeudaPago(obj: any){
    const arreglo: any[] = [];
    if (obj === null){ return []; }
    // eslint-disable-next-line guard-for-in
    for(const registro in obj){
      obj[registro].id = obj[registro].idCustom;
      delete obj[registro].idCustom;
      arreglo.push(obj[registro]);
    }
    return arreglo;
  }

}
