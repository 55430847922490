export class ContainerModel {

  id?: string;
  numero: number;
  cliente: string;
  ubicacion: string;
  fAlquiler?: Date;
  tamanio: string;
  tipo: string;
  precio: number;
  caracteristicas: string;
  observaciones: string;
  ordenDeCompra?: string;
  remito: number;
  modif: Date;
  fechaCambioPrecioAlquiler: Date;
  fechaVencimiento?: Date;
  historialDe?: string;
  usuario?: string;
  estado?: string;

  constructor() {
    this.modif = new Date();
    this.precio = 0;
    this.remito = 0;
    this.historialDe = '';
    this.tamanio='20';
    this.tipo='OFI-C';
    this.fechaCambioPrecioAlquiler = new Date();
  }

}
