export class DeudaModel {

  id?: string;
  cliente: string;
  contenedor: number;
  anio: number;
  mes: number;
  dia: number;
  precio: number;

  constructor() {
    this.cliente = '';
    this.contenedor = 0;
    this.anio = new Date().getFullYear();
    this.mes = new Date().getMonth()+1;
    this.dia = new Date().getDate();
    this.precio = 0;
  }

}
