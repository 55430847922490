import { Component } from '@angular/core';
import { ContService } from '../../services/contService.service';
import { ReportesService } from '../../services/reportes.service';

@Component({
  selector: 'app-informe',
  templateUrl: './informe.component.html'
})
export class InformeComponent {

  alquilados = 0;
  totalContainers = 0;
  factTotal = 0;
  saldoTotal = 0;
  campoId = '';
  depositosExtId = '';

  cargando = [false,false,false];

  totalDeudas = 0;
  totalPagos = 0;

  constructor(public contService: ContService, private reportesService: ReportesService) { }

  ionViewWillEnter() {
    this.alquilados = 0;
    this.totalContainers = 0;
    this.factTotal = 0;
    this.saldoTotal = 0;
    this.contService.getClientes().subscribe(res => {
      this.contService.listaClientes = res;
      this.campoId = this.contService.listaClientes.find(cli => cli.nombre === 'Campo').id;
      this.depositosExtId = this.contService.listaClientes.find(cli => cli.nombre === 'depositosExt').id;
      this.calcularInforme();
      this.calcularSaldoTotal();
    });
  }

  calcularInforme(){
    this.cargando = [true,true,true];
    this.contService.getContainers().subscribe(res => {
      this.contService.containers = res;
      this.totalContainers = this.contService.containers.length;
      this.contService.containers.forEach(cont => {
        let alquilado = true;
        if(cont.cliente === this.campoId || cont.cliente === this.depositosExtId) alquilado = false;
        if(alquilado) { this.alquilados++; }
        if(alquilado && (!cont.fechaVencimiento || this.contService.diferenciaMeses(cont.fechaVencimiento) === 0)){
          this.factTotal= this.factTotal + cont.precio;
        }
      });
      this.cargando[0] = false;
      this.cargando[1] = false;
    });
  }

  calcularSaldoTotal(){
    this.cargando[2] = true;
    this.saldoTotal = 0;
    this.contService.listaClientes.forEach(cliente =>{
      this.saldoTotal+= cliente.saldo + cliente.deudaInicial;
    });
    this.cargando[2] = false;
    // this.contService.listaClientes.forEach(cliente => {
    //   this.totalDeudas += cliente.deudaInicial;
    // });
    // this.reportesService.getAllDeudas().subscribe(res => {
    //   res.forEach(deuda => {
    //     this.totalDeudas += deuda.precio;
    //   });
    //   this.reportesService.getAllPagos().subscribe(resp => {
    //     resp.forEach(pago => {
    //       this.totalPagos += pago.cantidad;
    //     });
    //     this.saldoTotal = this.totalDeudas - this.totalPagos;
    //     this.cargando[2] = false;
    //   });
    // });
  }

}
