import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioModel } from 'src/app/models/usuario.model';
import jwt_decode from 'jwt-decode';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html'
})
export class RegistroComponent implements OnInit {

  usuario: UsuarioModel;

  constructor( private auth: AuthService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.usuario = new UsuarioModel();
  }

  onSubmit(form: NgForm){
    this.auth.registro( this.usuario).subscribe(res=> {

      console.log('response: ', res);

      const token = res['idToken'];
      const decoded = jwt_decode(token);
      const userId = decoded['user_id'];

      const user = {
        uid: userId,
        username: this.usuario.nombre
      };

      this.auth.crearUsuario(user).subscribe(resp => {
        // localStorage.setItem('username', this.usuario.nombre);
        // this.auth.username = resp[0].username;
        // this.auth.login(this.usuario).subscribe(res=> {
        //   this.userService.username = resp[0].username;
        //   this.router.navigateByUrl('/containers');
        // });
        this.auth.getUsuario(userId).subscribe(resp => {
          localStorage.setItem('username', resp[0].username);
          this.userService.username = resp[0].username;
          Swal.close();
          this.router.navigateByUrl('/containers');
        });
      });

    }, (err) => {

      let error_text = 'No se ha podido registrar el usuario';

      if(this.usuario.password.length < 6) error_text = 'La contraseña debe tener al menos 6 caracteres.';

      if(err.error.error.message == 'INVALID_EMAIL') error_text = 'La dirección de correo electrónico no es válida.';
      
      Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Error al registrar usuario',
        text: error_text
      });

      console.log(err.error.error.message);

    });
  }


}
