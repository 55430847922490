import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { menuController } from '@ionic/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {

  username: string;

  constructor(public menuCtrl: MenuController, private auth: AuthService, public userService: UserService) { }

  ionViewWillEnter() {
    //menuController.enable(true);
    this.username = this.userService.username;
  }


  logout(){
    menuController.close();
    //menuController.enable(false);
    localStorage.clear();
    this.auth.logout();
  }
}
