import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Firebase y angular fire
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';



//de HeroesApp
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//los containers
import { ContainerComponent } from './pages/container/container.component';
import { ContainersComponent } from './pages/containers/containers.component';

//reportes
import { ReportesComponent } from './pages/reportes/reportes.component';

//clientes
import { ClientesComponent } from './pages/clientes/clientes.component';

//Registro y login
import { LoginComponent } from './pages/login/login.component';
import { RegistroComponent } from './pages/registro/registro.component';

//menu
import { MenuComponent } from './pages/menu/menu.component';

//informe
import { InformeComponent } from './pages/informe/informe.component';

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent,
    ContainersComponent,
    ReportesComponent,
    ClientesComponent,
    MenuComponent,
    LoginComponent,
    RegistroComponent,
    InformeComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  exports: [MenuComponent],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
