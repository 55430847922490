import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UsuarioModel } from '../models/usuario.model';
import jwt_decode from 'jwt-decode';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: string;

  username: string;

  private url = 'https://identitytoolkit.googleapis.com/v1/accounts';
  private apiKey = 'AIzaSyCHvSTBzeFGOu5KauXQFQODZgyC43gtnRE';

  // Crear nuevo usuario
  // https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=[API_KEY]

  // Login
  // https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=[API_KEY]

  constructor( private http: HttpClient, private db: AngularFireDatabase, private router: Router, private userService: UserService) {
    this.leerToken();
   }

   crearUsuario( usuario){
    return this.http.post(`${ environment.firebase.databaseURL }/usuarios.json`, usuario);
  }

  getUsuario(userId){
    return this.db.list('usuarios', ref => ref.orderByChild('uid').equalTo(userId)).valueChanges().pipe( map( this.crearArreglo  ) );
    //return this.db.list('usuarios', ref => ref.orderByChild('uid').equalTo(usuario));
    //return this.http.get(`${ environment.firebase.databaseURL }/usuarios/${ usuario }.json`);
  }

  logout() {
    delete this.userToken;
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  login( usuario: UsuarioModel) {

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();

    const authData = {
      email: usuario.email,
      password: usuario.password,
      returnSecureToken: true
    };

    return this.http.post( `${ this.url + ':' }signInWithPassword?key=${this.apiKey}`, authData )
    .pipe(
      map( resp => {
        this.guardarToken( resp['idToken']);
        return resp;
      })
    );

  }

  iniciarSesion(user: UsuarioModel){
    this.login( user).subscribe(res=> {

      console.log('ENTRA LOGIN!!!');
      Swal.close();

      const token = res['idToken'];
      const decoded = jwt_decode(token);
      const uid = decoded['user_id'];

      this.getUsuario(uid).subscribe(resp => {
        console.log('ENTRA GETUSUARIO!!!');
        localStorage.setItem('username', resp[0].username);
        this.userService.username = resp[0].username;
        this.router.navigateByUrl('/containers');
      });

    }, (err) => {

      Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Error al iniciar sesión',
        text: 'Ha habido un error al iniciar sesión'
      });
      console.log(err.error.error.message);

    });
  }

  registro( usuario: UsuarioModel) {

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();

    const authData = {
      email: usuario.email,
      password: usuario.password,
      nombre: usuario.nombre,
      nivel: 0,
      returnSecureToken: true
    };

    return this.http.post( `${ this.url + ':' }signUp?key=${this.apiKey}`, authData ).pipe(
      map( resp => {
        this.guardarToken( resp['idToken']);
        return resp;
      })
    );

  }

  leerToken(){
    if(localStorage.getItem('token')){
      this.userToken = localStorage.getItem('token');
    }else{
      this.userToken = '';
    }

    return this.userToken;
  }

  estaAutenticado(): boolean {
    if(this.userToken.length < 2) {
      return false;
    };
    return true;
  }

  private guardarToken( idToken: string) {
    this.userToken = idToken;
    localStorage.setItem('token', idToken);
  }

  private crearArreglo(obj: any){
    const arreglo: any[] = [];
    if (obj === null){ return []; }
    for(let registro in obj){
      obj[registro].id = registro;
      arreglo.push(obj[registro]);
    }
    return arreglo;
  }

}
