export class ClienteModel {

  id?: string;
  nombre: string;
  deudaInicial: number;
  saldo?: number;

  constructor() {
    this.nombre = '';
    this.deudaInicial = 0;
    this.saldo = 0;
  }

}
