/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ContainerModel } from '../../models/container.model';
import { NgForm, FormGroup } from '@angular/forms';
import { ContService } from '../../services/contService.service';

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html'
})
export class ContainerComponent implements OnInit {

  container = new ContainerModel();
  containerOrig: ContainerModel;
  historial: ContainerModel[] = [];
  edit = false;
  mostrarHistorial = false;
  id = this.route.snapshot.paramMap.get('id');

  listaClientes: any[] = [];
  //nombreClienteContainer: string;

  constructor( private contService: ContService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private userService: UserService) { }

  ngOnInit() {

    this.getClientes();

    if (this.id !== 'nuevo') {
      this.edit = true;
      this.getContainer();
    }


  }

  getNombreCliente(id){
    return this.listaClientes.find(x => x.id === id).nombre;
  }

  get fechaCambioPrecio(){
    const fecha = new Date(this.container.fechaCambioPrecioAlquiler);
    return `${fecha.getMonth()+1}/${fecha.getFullYear()}`
  }

  getContainer(){
    this.contService.getContainer(this.id).subscribe( (container: ContainerModel) => {
      this.container = container;
      container.id = this.id;
      this.container.id = container.id;
      this.getHistorialContainer();
    });
  }

  cancelEdit(){
    this.getContainer();
    this.edit = true;
  }

  getHistorialContainer(){
    this.contService.getHistorialContainer(this.id).subscribe( respuesta => {
      this.historial = respuesta;
    });
  }

  precioCambiado(){
    const containerSinEditar = this.historial[this.historial.length -1];
    if (!containerSinEditar) {
      console.error('No se encuentra containerSinEditar');
      return;
    }
    if (this.container.precio !== containerSinEditar.precio ) {
      return true;
    }else{
      return false;
    }
  }

  guardar(form: NgForm){

    if (form.invalid) {
      Swal.fire({
        title: 'Faltan campos obligatorios',
        text: 'Los campos "contenedor n°", "precio de alquiler" y "cliente" son obligatorios',
        icon: 'error',
        allowOutsideClick: false
      });
      return;
    }

    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      icon: 'info',
      allowOutsideClick: false
    });

    Swal.showLoading();

    this.container.usuario = this.userService.username;

    let peticion: Observable<any>;

    let alquilado = true;
    if(this.getNombreCliente(this.container.cliente) === ('Campo' || 'depositosExt')) alquilado = false;

    if(!alquilado){
      //this.container.ubicacion = 'Campo';
      this.container.fAlquiler = new Date();
      //this.container.fechaCambioPrecioAlquiler = new Date();
      delete(this.container.fechaVencimiento);
    }

    if (this.container.id) {
      this.container.modif = new Date();
      //if (this.precioCambiado()) { this.container.fechaCambioPrecioAlquiler = new Date(); }
      peticion = this.contService.actualizarContainer( this.container );
    }else{
      //this.container.fechaCambioPrecioAlquiler = new Date();
      peticion = this.contService.crearContainer( this.container );
    }


    peticion.subscribe( resp => {
      if (this.container.fechaCambioPrecioAlquiler) {
        delete this.container.fechaCambioPrecioAlquiler;
      }
      this.contService.pushToHistorial(this.container).subscribe( respuesta => {
        Swal.fire({
          title: this.container.numero,
          text: 'Se ha actualizado',
          icon: 'success'
        });

        this.edit = true;
        this.router.navigateByUrl('/containers');
      });
    });
  }

  deleteContainer(){
    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que desea eliminar el container #${ this.container.numero }?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.isConfirmed) {

        Swal.fire(
          'Eliminando contenedor',
          'Espera mientras el contenedor es eliminado...'
        )
        Swal.showLoading();
        Swal.isLoading();
        this.contService.getHistorialContainer(this.container.id).subscribe(hist=>{
          console.log(hist);
          hist.forEach(contHist => {
            console.log(contHist.id);
            this.contService.borrarHistorial(contHist.id).subscribe();
          })
          this.contService.borrarContainer(this.container.id).subscribe(res=>{
            Swal.close();
            this.router.navigateByUrl('/containers');
          })
        });
      }
    })
    // .then( resp => {
    //   if (resp.value) {
    //     Swal.showLoading();
    //     Swal.disableButtons();
    //     //this.contService.borrarContainer( this.container.id ).subscribe();  
    //   }

    // });
  }

  /*
  cambioCliente(){ //si cambió el cliente en el edit, se registra el cambio en la base

    const ultimoHistorial = this.historial.slice(-2)[0];
    if (this.container.cliente !== ultimoHistorial.cliente ) {
      const cambio = {
        container: this.container.id,
        cliente: this.container.cliente,
        fecha: new Date()
      };
      this.contService.cambioCliente(cambio).subscribe( respuesta => {
        console.log(respuesta);
      });
    }
  }
  */

  getClientes(){
    this.contService.getClientes().subscribe( respuesta => {
      this.listaClientes = respuesta;
      this.listaClientes.sort((a, b)=> {
        if (a.nombre > b.nombre) {return 1;}
        if (a.nombre < b.nombre) {return -1;}
        return 0;
      });
    });
  }

  // checkFecha(){
  //   const fecha = new Date(this.container.fAlquiler);
  //   const anio = fecha.getFullYear();
  //   const mes = fecha.getMonth() +1;
  //   console.log(mes + '/' + anio);
  // }

  formatearFecha(fecha){
    const dia = fecha.substring(8,10);
    const mes = fecha.substring(5,7);
    const anio = fecha.substring(0,4);
    const fechaFormat = dia + '/' + mes + '/' + anio;
    return fechaFormat;
  }

  verContainerHistorial(historial){
    this.containerOrig = this.container;
    this.container = historial;
    this.mostrarHistorial = false;
  }

  cancelarViewHist(){
    this.container = this.containerOrig;
    delete this.containerOrig;
  }

}
